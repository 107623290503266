<template>
  <div class="news">
    <div class="container">
      <div class="news_item" v-for="item in list">
        <span class="icon_round"></span>

        <div>
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 首页-新闻 */

export default {
  data() {
    return {
      list: [
        {
          name: "住建部门：施工总承包、施工专业承包、工程勘察、工程设计、工程监理、工程造价咨询、设计施工一体化、工程招标代理、质量检测机构、安全生产许可证。",
          path: "",
        },
        {
          name: "交通部门：公路工程监理、水运工程监理、交通质量检测机构、交通运输企业安全生产标准化。",
          path: "",
        },
        {
          name: "水利部门：水利工程施工监理、水运保持工程施工监理、机电及金属结构设备制造监理、水利工程建设环境保护监理、水利质量检测机构、水利安全生产标准化、中国水利协会灌溉企业登记。",
          path: "",
        },
        {
          name: "自然资源部门：城乡规划编制、测绘单位、地址灾害治理工程、地址灾害危险性评估。",
          path: "",
        },
        { name: "国家能源局：承装（修、试）电力设施许可证。", path: "" },
        {
          name: "国家文物局：文物保护工程施工资质、监理资质、勘察设计资质。",
          path: "",
        },
        { name: "应急管理部门：安全生产检查机构、安全评价机构。", path: "" },
        { name: "国家发改委：工程咨询单位名录。", path: "" },
        {
          name: "工业和信息化部：特种设备（压力管道）资质，计算机信息系统集成、运行维护分项、信息通信建设系统集成服务能力。",
          path: "",
        },
        { name: "财政部：政府采购代理机构。", path: "" },
        { name: "商务部：机电产品国际招标代理机构。", path: "" },
        { name: "公安部：爆破从业单位、消防技术服务机构。", path: "" },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.news {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  margin-bottom: 40px;
  background-color: #fff;

  .news_item {
    padding: 30px 0px;
    // font-size: 16px;
    line-height: 28px;
    color: #333;
    border-bottom: 1px dashed #cacaca;
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon_round {
      width: 13px;
      height: 13px;
      background-color: #1d89e3;
      margin-right: 22px;
      border-radius: 50%;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      color: #1d89e3;
    }
  }
}
</style>
