<template>
  <div class="service">
    <div class="main">
      <div class="title">
        <div>我们的服务</div>
        <div class="english_text">SERVICE</div>
      </div>

      <div class="btns">
        <div
          :class="['btn_item', index == btnActiveIndex ? 'active' : '']"
          v-for="(item, index) in btnList"
          :key="'a' + item.name"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 首页-我们的服务 */

export default {
  data() {
    return {
      btnList: [
        { name: "查企业", path: "" },
        { name: "查资质", path: "" },
        { name: "查在建", path: "" },
        { name: "查荣誉奖项", path: "" },
        { name: "查最新标讯", path: "" },
        { name: "查拟建项目", path: "" },
        { name: "企业报告", path: "" },
        { name: "监控服务", path: "" },
        { name: "查业绩", path: "" },
        { name: "查人员", path: "" },
        { name: "查信用评价", path: "" },
        { name: "查处罚风险", path: "" },
        { name: "组合查询", path: "" },
        { name: "公路工程定制版", path: "" },
        { name: "定制开发", path: "" },
        { name: "", path: "" },
      ],
      btnActiveIndex: 0,
      timer: null,
    };
  },

  methods: {
    __init__() {
      this.timer = setInterval(() => {
        if (this.btnActiveIndex < this.btnList.length - 1) {
          this.btnActiveIndex++;
        } else {
          this.btnActiveIndex = 0;
        }
      }, 2000);
    },
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.service {
  padding: 40px;
  background-color: #fff;

  .main {
    color: #fff;
    // font-size: 22px;

    .title {
      background: url("https://www.zizhihelp.com/upload/static/web/beijing4.png")
        no-repeat center center / cover;
      padding: 20px;
      font-size: 36px;
      line-height: 56px;

      .english_text {
        font-size: 26px;
        line-height: 42px;
      }
    }

    .btns {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;

      .btn_item {
        width: calc(100% / 8);
        background-color: #1d89e3;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        line-height: 37px;
        padding: 15px 0px;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
      }

      .active,
      .btn_item:hover {
        background-color: #e8f3fd;
        color: #1d89e3;
      }
    }
  }
}
</style>
