<template>
  <div class="advantage">
    <div class="container">
      <div class="title">资质帮行业优势</div>
      <div class="desc">海量数据，精准覆盖，覆盖全行业99%的建筑企业</div>

      <div class="flex alc mb90">
        <img
          class="img"
          src="https://www.zizhihelp.com/upload/static/web/tupian1.png"
          alt=""
        />

        <div>
          <div class="advantage_item">
            <div class="title">
              <div class="index">1</div>

              <div class="text">上亿级数据积淀</div>
            </div>

            <div class="desc">
              156万+企业携手大数据算法、千万级业绩、1422万+人员数据、日更新12万+数据
            </div>

            <span class="try_btn">立即体验</span>
          </div>

          <div class="advantage_item">
            <div class="title">
              <div class="index">2</div>

              <div class="text">大数据智能算法</div>
            </div>

            <div class="desc">
              建设通大数据研究助力产品服务智能化升级，建立标准模型，助力企业把握商机
            </div>

            <span class="try_btn">立即体验</span>
          </div>
        </div>
      </div>

      <div class="flex alc">
        <div>
          <div class="advantage_item">
            <div class="title">
              <div class="index">3</div>

              <div class="text">专业线下团队</div>
            </div>

            <div class="desc">
              配置一对一专业线下售后服务，为您提供咨询，赋能建企创新转型升级
            </div>

            <span class="try_btn">立即体验</span>
          </div>
        </div>

        <img
          class="img2"
          src="https://www.zizhihelp.com/upload/static/web/tupian2.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
/* 首页-优势 */

export default {};
</script>

<style lang="less" scoped>
.advantage {
  background: url("https://www.zizhihelp.com/upload/static/web/beijing3.png")
    no-repeat center center / cover;
  padding: 100px 0px;
  box-sizing: border-box;
  color: #fff;
  .container {
    width: 1400px;
  }

  .title {
    text-align: center;
    font-size: 46px;
    line-height: 65px;
    margin-bottom: 30px;
    font-weight: bold;
  }

  .desc {
    text-align: center;
    font-size: 26px;
    line-height: 37px;
    margin-bottom: 130px;
  }

  .img {
    height: 540px;
    margin-right: 50px;
  }

  .img2 {
    height: 282px;
    margin-left: 15px;
  }

  .advantage_item {
    margin-bottom: 40px;

    .title {
      display: flex;
      align-items: center;
      font-weight: normal;
      font-size: 34px;

      .index {
        width: 55px;
        line-height: 55px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.4);
        flex-shrink: 0;
        margin-right: 15px;
      }
    }

    .desc {
      text-align: left;
      margin-bottom: 30px;
      font-size: 20px;
    }

    .try_btn {
      font-size: 20px;
      padding: 5px 18px 8px;
      border-radius: 23.5px;
      background-color: #fff;
      color: #1d89e3;
      line-height: 37px;
      cursor: pointer;
      letter-spacing: 2px;
      border: 1px solid #fff;

      &:hover {
        color: #fff;
        background-color: #409eff;
      }
    }
  }
}
</style>
