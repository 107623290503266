import { render, staticRenderFns } from "./feature.vue?vue&type=template&id=1348f819&scoped=true&"
import script from "./feature.vue?vue&type=script&lang=js&"
export * from "./feature.vue?vue&type=script&lang=js&"
import style0 from "./feature.vue?vue&type=style&index=0&id=1348f819&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1348f819",
  null
  
)

export default component.exports