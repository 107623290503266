<template>
  <div class="advantage">
    <div class="main">
      <div class="item" v-for="item in list">
        <img class="icon" :src="item.iconUrl" alt="" />

        <div class="right">
          <div class="title mb15">
            <span class="num">
              {{ `${item.num}万+` }}
            </span>
            <span class="name">{{ item.name }}</span>
          </div>

          <div class="desc">
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 首页-特点 */

export default {
  data() {
    return {
      list: [
        {
          iconUrl: "https://www.zizhihelp.com/upload/static/web/jianzhu.png",
          num: 156,
          name: "建筑业企业",
          desc: "覆盖99%的建筑行业，多数据维度快速组合",
        },
        {
          iconUrl: "https://www.zizhihelp.com/upload/static/web/yeji.png",
          num: 1302,
          name: "中标业绩",
          desc: "日更新12万+中标项目信息，千万级中标数",
        },
        {
          iconUrl: "https://www.zizhihelp.com/upload/static/web/renhyuan.png",
          num: 1422,
          name: "人员信息",
          desc: "项目经理、造价师、注册安全师等各类注册人",
        },
        {
          iconUrl: "https://www.zizhihelp.com/upload/static/web/gengxin.png",
          num: 12,
          name: "日更新数据",
          desc: "7x24小时实时更新，享受第一手新鲜资讯",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.advantage {
  background: url("https://www.zizhihelp.com/upload/static/web/beijing2.png")
    no-repeat center center / cover;
  padding: 50px 0px;
  color: #fff;

  .main {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .item {
      flex: 1;
      display: flex;
      align-items: baseline;

      .icon {
        width: 36px;
        height: 36px;
        margin-right: 18px;
      }

      .right {
        .title {
          font-size: 20px;
          .num {
            font-size: 36px;
            font-weight: 500;
            line-height: 65px;
          }

          .name {
            line-height: 37px;
          }
        }

        .desc {
          font-size: 14px;
          line-height: 28px;
        }
      }
    }
  }
}
</style>
