<template>
  <div class="recommend_company">
    <div class="container">
      <div class="title">推荐公司</div>

      <div class="nav">
        <div
          :class="['nav_item', index == navActiveIndex ? 'active' : '']"
          v-for="(item, index) in navTab"
          @click="handleNavChange(item, index)"
        >
          {{ item.name }}
        </div>
      </div>

      <div class="list">
        <div
          class="list_item"
          v-for="item in companyList"
          :key="item.id"
          @click="toDetail(item.id)"
        >
          <div class="top">
            <span class="circle_name">{{ item.name[0] }}</span>
            <div class="name">{{ item.name }}</div>
          </div>

          <div class="desc">
            {{ item.remark }}
          </div>

          <div class="tags" v-if="item.companyTag.length > 0">
            <div
              :class="['tag', `tag${index1}`]"
              v-for="(tag, index1) in item.companyTag"
              :key="tag"
            >
              {{ tag }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 首页-推荐公司 */
// 引入ajax函数
import { getAllCompanyList, getAlignList } from "@/api/home";

export default {
  data() {
    return {
      navTab: [
        {
          name: "全部",
        },
        { name: "加盟" },
      ],
      navActiveIndex: 0,

      companyList: [],
      AllCompanyList: [],
      AlignCompanyList: [],
    };
  },

  methods: {
    /* 获取数据 */
    // 全部
    async getAllData() {
      let params = { pageNum: 1, pageSize: 6 };
      let res = await getAllCompanyList(params);
      let temp = this.processData(res.records);
      this.AllCompanyList = temp;
      this.companyList = this.AllCompanyList;
    },
    // 加盟
    async getAlignData() {
      let params = { pageNum: 1, pageSize: 6 };
      let res = await getAlignList(params);
      let temp = this.processData(res.records);
      this.AlignCompanyList = temp;
    },
    // 处理数据
    processData(arr) {
      arr.forEach((v) => {
        if (v.companyTag) {
          v.companyTag = v.companyTag.split(",");
        }
      });
      return arr;
    },

    /* 操作 */
    // 跳转详情
    toDetail(id) {
      this.$router.push({
        name: "companyDetails",
        params: { name: "资质等级" },
        query: { id },
      });
    },
    // 点击nav的事件
    handleNavChange(item, index) {
      this.navActiveIndex = index;
      if (index == 0) {
        this.companyList = this.AllCompanyList;
      } else if (index == 1) {
        this.companyList = this.AlignCompanyList;
      }
    },
    // 初始化
    __init__() {
      this.getAllData();
      this.getAlignData();
    },
  },

  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.recommend_company {
  color: #333;
  font-size: 16px;
  padding: 40px 0;
  background-color: #f9f9f9;

  .title {
    // font-size: 22px;
    line-height: 37px;
    border-bottom: 1px solid #efefef;
  }

  .nav {
    margin: 24px 0px 30px;
    display: flex;
    align-content: center;
    .nav_item {
      line-height: 28px;
      padding: 4px 16px;
      border-radius: 4px;
      background-color: #fff;
      color: #666;
      margin-right: 10px;
      cursor: pointer;
    }

    .active,
    .nav_item:hover {
      background-color: #1d89e3;
      color: #fff;
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .list_item {
      width: calc((100% - 20px) / 2);
      margin-right: 20px;
      margin-bottom: 40px;
      padding: 20px;
      background-color: #fff;
      border-radius: 8px;
      cursor: pointer;

      .top {
        display: flex;
        align-items: center;
        // font-size: 20px;
        color: #333;

        .circle_name {
          width: 35px;
          line-height: 35px;
          color: #fff;
          background-color: #1d89e3;
          border-radius: 50%;
          margin-right: 10px;
          text-align: center;
          // font-size: 18px;
        }
      }

      .desc {
        font-size: 16px;
        color: #666;
        overflow: hidden; // 溢出隐藏
        text-overflow: ellipsis; // 溢出用省略号显示
        display: -webkit-box; // 作为弹性伸缩盒子模型显示。
        -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式：从上到下垂直排列
        -webkit-line-clamp: 1; // 显示的行数
        margin: 14px 0px;
      }

      .tags {
        display: flex;
        align-items: center;

        .tag {
          font-size: 14px;
          color: #333333;
          line-height: 25px;
          padding: 3px 12px;
          border-radius: 15px;
          margin-right: 10px;
        }

        .tag0 {
          background-color: #d7edfb;
        }

        .tag1 {
          background-color: #dcf0ef;
        }

        .tag2 {
          background-color: #fef1e1;
        }

        .tag3 {
          background-color: #f7ebf5;
        }

        .tag4 {
          background-color: #ffab91;
        }
      }

      &:nth-child(2n) {
        margin-right: 0px;
      }

      &:hover {
        .top {
          color: #1d89e3 !important;
        }
      }
    }
  }
}
</style>
