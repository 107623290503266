<template>
  <div class="recent_info">
    <div class="container">
      <div class="top_wrapper">
        <div class="tabs">
          <div
            :class="['tab_item', index == tabsActiveIndex ? 'active' : '']"
            v-for="(item, index) in tabsData"
            :key="item"
            @click="handleTabChange(index)"
          >
            {{ item }}
          </div>
        </div>

        <div class="right_wrapper">
          <div class="add">
            <img
              class="icon_add"
              src="https://www.zizhihelp.com/upload/static/web/dingwei.png"
              alt=""
            />
            <span>乐山</span>
          </div>

          <div class="change_btn">切换城市</div>
        </div>
      </div>

      <div class="list">
        <div class="list_item" v-for="item in 10">
          <div class="name">
            2024-2026年度信息科技通用人力外包服务入围项目(2024-2026年度信息科技通用人力外包服务入围项目)-更正公告
          </div>

          <div class="bottom_wrapper">
            <div class="tags">
              <div class="tag tag0">变更</div>
              <div class="tag">四川省-乐山市-市中区</div>
              <div class="tag">2个联系人</div>
            </div>

            <div class="time">发布时间：1小时前</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 首页-最新信息 */
// 引入ajax函数
import { searchRecent } from "@/api/home";

export default {
  data() {
    return {
      //   tabsData: ["最近中标公司", "项目经理中标", "最新招标项目"],
      tabsData: ["最新招标", "最新中标", "最新中标企业", "最新招采企业"],
      tabsActiveIndex: 0, // tab的激活索引
      noticeDtoList: [],
      recentManagerList: [],
      recentResultDtoList: [],
    };
  },

  methods: {
    // tab的点击事件
    handleTabChange(index) {
      this.tabsActiveIndex = index;
    },

    // 获取数据
    async getData() {
      let res = await searchRecent();
      this.noticeDtoList = res.noticeDtoList;
      this.recentManagerList = res.recentManagerList;
      this.recentResultDtoList = res.recentResultDtoList;
    },

    // 初始化
    __init__() {
      this.getData();
    },
  },

  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.recent_info {
  padding: 40px;
  background-color: #fff;
  font-size: 16px;

  .top_wrapper {
    display: flex;
    align-content: center;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;
    color: #666666;

    .tabs {
      display: flex;
      align-items: center;

      .tab_item {
        line-height: 28px;
        margin-right: 36px;
        padding: 10px 0px;
        cursor: pointer;
      }

      .active,
      .tab_item:hover {
        color: #333333;
        border-bottom: 2px solid #1d89e3;
      }
    }

    .right_wrapper {
      display: flex;
      align-items: center;

      .add {
        display: flex;
        align-items: center;
        .icon_add {
          width: 18px;
          height: 20px;
          margin-right: 8px;
        }
      }

      .change_btn {
        color: #333;
        line-height: 28px;
        padding: 2px 15px;
        border: 1px solid #999999;
        border-radius: 4px;
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #efefef;

    .list_item {
      width: calc((100% - 82px) / 2);
      margin-right: 82px;
      padding: 40px 0px;
      border-top: 1px dashed #cacaca;
      cursor: pointer;

      .name {
        color: #333;
        // font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .bottom_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tags {
          display: flex;
          align-items: center;

          .tag {
            font-size: 14px;
            color: #666666;
            padding: 2px 6px;
            background-color: #efefef;
            border-radius: 4px;
            margin-right: 10px;
            line-height: 22px;
          }

          .tag0 {
            color: #1d89e3;
            background-color: #d7edfb;
          }
        }

        .time {
          color: #999999;
          // font-size: 16px;
        }
      }

      &:nth-child(2n) {
        margin-right: 0px;
      }

      &:nth-child(1),
      &:nth-child(2) {
        border-top: none;
      }

      &:hover {
        .name {
          color: #1d89e3;
        }
      }
    }
  }
}
</style>
