<template>
  <div class="home">
    <!-- 搜索banner -->
    <banner />
    <!-- 推荐公司 -->
    <recommendCompany />
    <!-- 优势 -->
    <feature />
    <!-- 最近信息 -->
    <recentInfo />
    <!-- 行业优势 -->
    <advantage />
    <!-- 我们的服务 -->
    <service />
    <!-- 新闻 -->
    <news />
  </div>
</template>

<script>
import banner from "./compoents/banner.vue";
import recommendCompany from "./compoents/recommendCompany.vue";
import feature from "./compoents/feature.vue";
import recentInfo from "./compoents/recentInfo.vue";
import advantage from "./compoents/advantage.vue";
import service from "./compoents/service.vue";
import news from "./compoents/news.vue";

export default {
  components: {
    banner,
    recommendCompany,
    feature,
    recentInfo,
    advantage,
    service,
    news,
  },
};
</script>

<style lang="less" scoped>
.home {
  height: 100%;
}
</style>
